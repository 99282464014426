.page-section {
    &.lg {
        margin-bottom: 80px;
    }
    &.md {
        margin-bottom: 40px;
    }
    &.sm {
        margin-bottom: 24px;
    }
    h2 {
        margin-bottom: 25px;
        font-size: 22px;
        border-bottom: 1px dashed rgba(3, 66, 100, 0.15);
        padding-bottom: 10px;
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
    }

    @media print {
        page-break-inside: avoid;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
