.beacon-info {
    margin-top: $md-spacing-size;

    .beacon-info-endorsement {
        max-width: 800px;
        padding: $md-spacing-size;
    }
    .beacon-info-description {
        padding: $md-spacing-size;
        margin: $lg-spacing-size 0;
    }
    .beacon-name {
        margin-bottom: 1vh;
        font-weight: bold;
    }
}
