.beacon-endorsement {
    color: #222;
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;

    .endorsement-circle {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: inline-block;
        background: #ddd;
        &.Avoid {
            background: #ef6c00;
        }
    }
}
