.story-linked-items {
    .story-linked-items-item {
        .story-linked-items-item-explanation {
            margin: $xxl-spacing-size 0;
        }
        margin-bottom: $xxl-spacing-size;
        padding: $xxl-spacing-size $xl-spacing-size;
    }
    .story-linked-items-options {
        & > a {
            margin: $md-spacing-size $md-spacing-size $md-spacing-size 0;
        }
        .story-linked-items-options-selected {
            position: relative;
            &::after {
                content: "selected";
                position: absolute;
                top: -15px;
                left: 0;
                font-size: 11px;
                line-height: 11px;
                color: $subdued;
            }
        }
        .story-linked-items-options-considered {
            position: relative;
            margin-top: $xxl-spacing-size;
            &::after {
                content: "considered";
                position: absolute;
                top: -15px;
                left: 0;
                font-size: 11px;
                line-height: 11px;
                color: $subdued;
            }
        }
    }
    .story-linked-items-item-outcome {
        border-radius: 0 0 4px 4px;
        position: relative;
        display: flex;
        margin-top: 1.5 * $xxl-spacing-size;

        & > div {
            &:first-child {
                padding-right: 16px;
            }
        }

        &::after {
            content: "outcome";
            position: absolute;
            top: -15px;
            left: 0;
            font-size: 11px;
            line-height: 11px;
            color: $subdued;
        }
    }
}
