.story-detail-linked-items-header {
    margin-top: 2 * $xxl-spacing-size !important;
}
.story-detail-linked-items {
    background: rgba(0, 0, 0, 0.1);
    padding: $lg-spacing-size $lg-spacing-size $xl-spacing-size;
}
.avatar-placeholders,
.image-placeholders {
    display: flex;
    & > * {
        margin-right: $sm-spacing-size;
    }
}
