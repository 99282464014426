h3.section-header {
    display: inline-block;

    .material-icons {
        position: relative;
        margin-left: 5px;
        top: 3px;
        font-size: 18px;
        color: $secondary-color;
    }
}
