.story-item {
    padding-top: $xl-spacing-size;
    .story-item-author {
        margin-top: $md-spacing-size;
    }
    .story-item-description {
        margin-top: $xxl-spacing-size;
    }

    .story-item-outcome {
        margin-top: $xxl-spacing-size;
        .story-item-outcome-inner {
            border-radius: 4px;
            .story-item-outcome-section {
                margin-top: $lg-spacing-size;

                .story-item-outcome-section-subtext {
                    display: block;
                    margin-top: -8px;
                    color: $subdued;
                }
            }
        }
    }
}
