.edit-button {
    position: absolute !important;
    top: 80px;
    right: 16px;
}

.DetailSnackbar {
    padding: 2em;
}

.beacon-detail-page {
    .beacon-detail-background {
        padding: $xl-spacing-size;
    }
    .beacon-detail-page-stories-header {
        margin-top: $xxl-spacing-size;
    }
    .beacon-detail-page-author {
        margin-top: $xl-spacing-size;
    }
    .beacon-detail-page-linked-people {
        margin-top: $xl-spacing-size;
    }
}

.meta-details {
    float: right;
    .MuiChip-outlined {
        border: none;
        margin-left: 20px;

        .MuiChip-icon {
            color: #999;
            width: 24px;
            height: 24px;
        }
        .MuiChip-label {
            font-size: 16px;
        }
    }
}

.beacon-info-tags {
    float: right;
    clear: both;

    .tag-list-chip.MuiChip-outlined {
        margin-bottom: 0;
    }
}
