.stretched-content {
    margin-left: -$content-spacing-size;
    margin-right: -$content-spacing-size;

    table.full-width {
        tr th:first-child,
        tr td:first-child {
            padding-left: 40px;
        }
        tr th:last-child,
        tr td:last-child {
            padding-right: 40px;
        }
    }

    .page-action-bar {
        margin: 25px 50px;
    }
}
