.markdown-editor {
    label {
        padding: 16px 8px;
        border-radius: 4px 4px 0 0;
        border: 1px solid #c4c4c4;
        border-bottom: none;

        &.empty {
            padding: 0;
        }

        // @media (prefers-color-scheme: dark) {
        //     background: rgba(255, 255, 255, 0.09);
        // }
    }
    &.focused {
        label {
            color: $primary-color;
        }
    }
}

.CodeMirror-lines {
    padding: 4px 0;
}
.CodeMirror pre {
    padding: 0 4px;
}
.CodeMirror-gutter-filler,
.CodeMirror-scrollbar-filler {
    background-color: #fff;
}
.CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
}
.CodeMirror-linenumber {
    padding: 0 3px 0 5px;
    min-width: 20px;
    text-align: right;
    color: #999;
    white-space: nowrap;
}
.CodeMirror-guttermarker {
    color: #000;
}
.CodeMirror-guttermarker-subtle {
    color: #999;
}
.CodeMirror-cursor {
    border-left: 1px solid;
    border-right: none;
    width: 0;
}
.CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
    width: auto;
    border: 0 !important;
    background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1;
}
.cm-animate-fat-cursor {
    width: auto;
    border: 0;
    -webkit-animation: blink 1.06s steps(1) infinite;
    -moz-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
    background-color: #7e7;
}
@-moz-keyframes blink {
    50% {
        background-color: transparent;
    }
}
@-webkit-keyframes blink {
    50% {
        background-color: transparent;
    }
}
@keyframes blink {
    50% {
        background-color: transparent;
    }
}
.cm-tab {
    display: inline-block;
    text-decoration: inherit;
}
.CodeMirror-ruler {
    border-left: 1px solid #c4c4c4;
    position: absolute;
}
.cm-s-default .cm-header {
    color: #00f;
}
.cm-s-default .cm-quote {
    color: #090;
}
.cm-negative {
    color: #d44;
}
.cm-positive {
    color: #292;
}
.cm-header,
.cm-strong {
    font-weight: 700;
}
.cm-em {
    font-style: italic;
}
.cm-link {
    text-decoration: underline;
}
.cm-strikethrough {
    text-decoration: line-through;
}
.cm-s-default .cm-keyword {
    color: #708;
}
.cm-s-default .cm-atom {
    color: #219;
}
.cm-s-default .cm-number {
    color: #164;
}
.cm-s-default .cm-def {
    color: #00f;
}
.cm-s-default .cm-variable-2 {
    color: #05a;
}
.cm-s-default .cm-variable-3 {
    color: #085;
}
.cm-s-default .cm-comment {
    color: #a50;
}
.cm-s-default .cm-string {
    color: #a11;
}
.cm-s-default .cm-string-2 {
    color: #f50;
}
.cm-s-default .cm-meta,
.cm-s-default .cm-qualifier {
    color: #555;
}
.cm-s-default .cm-builtin {
    color: #30a;
}
.cm-s-default .cm-bracket {
    color: #997;
}
.cm-s-default .cm-tag {
    color: #170;
}
.cm-s-default .cm-attribute {
    color: #00c;
}
.cm-s-default .cm-hr {
    color: #999;
}
.cm-s-default .cm-link {
    color: #00c;
}
.cm-invalidchar,
.cm-s-default .cm-error {
    color: red;
}
.CodeMirror-composing {
    border-bottom: 2px solid;
}
div.CodeMirror span.CodeMirror-matchingbracket {
    color: #0f0;
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: #f22;
}
.CodeMirror-matchingtag {
    background: rgba(255, 150, 0, 0.3);
}
.CodeMirror-activeline-background {
    background: #e8f2ff;
}
.CodeMirror {
    position: relative;
    overflow: hidden;
    background: #fff;
}
.CodeMirror-scroll {
    overflow: scroll !important;
    margin-bottom: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    height: 100%;
    outline: 0;
    position: relative;
}
.CodeMirror-sizer {
    position: relative;
    border-right: 30px solid transparent;
}
.CodeMirror-gutter-filler,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-vscrollbar {
    position: absolute;
    z-index: 6;
    display: none;
}
.CodeMirror-vscrollbar {
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}
.CodeMirror-hscrollbar {
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
    right: 0;
    bottom: 0;
}
.CodeMirror-gutter-filler {
    left: 0;
    bottom: 0;
}
.CodeMirror-gutters {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    z-index: 3;
}
.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: -30px;
}
.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    background: 0 0 !important;
    border: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.CodeMirror-gutter-background {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 4;
}
.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
}
.CodeMirror-lines {
    cursor: text;
    min-height: 1px;
}
.CodeMirror pre {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-width: 0;
    background: 0 0;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
}
.CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
}
.CodeMirror-linebackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}
.CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    overflow: auto;
}
.CodeMirror-code {
    outline: 0;
}
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber,
.CodeMirror-scroll,
.CodeMirror-sizer {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.CodeMirror-cursor {
    position: absolute;
}
.CodeMirror-measure pre {
    position: static;
}
div.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3;
}
.CodeMirror-focused div.CodeMirror-cursors,
div.CodeMirror-dragcursors {
    visibility: visible;
}
.CodeMirror-selected {
    background: #d9d9d9;
}
.CodeMirror-focused .CodeMirror-selected,
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
    background: #d7d4f0;
}
.CodeMirror-crosshair {
    cursor: crosshair;
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
    background: #d7d4f0;
}
.cm-searching {
    background: #ffa;
    background: rgba(255, 255, 0, 0.4);
}
.cm-force-border {
    padding-right: 0.1px;
}
@media print {
    .CodeMirror div.CodeMirror-cursors {
        visibility: hidden;
    }
}
.cm-tab-wrap-hack:after {
    content: "";
}
span.CodeMirror-selectedtext {
    background: 0 0;
}
.CodeMirror {
    height: auto;
    min-height: 25px;
    border: 1px solid #c4c4c4;
    border-top: none;
    padding: $lg-spacing-size;
    font: inherit;
    z-index: 0;
    border-radius: 0 0 4px 4px;
    @media (prefers-color-scheme: dark) {
        background: rgba(255, 255, 255, 0.09);
    }
}

.CodeMirror-focused {
    border: 2px solid $primary-color;
}
.CodeMirror-scroll {
    min-height: 25px;
}
.CodeMirror-fullscreen {
    background: #fff;
    position: fixed !important;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    z-index: 9;
}
.CodeMirror-sided {
    width: 50% !important;
}
.editor-toolbar {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.05);
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
}
.editor-toolbar:after,
.editor-toolbar:before {
    display: block;
    content: " ";
    height: 1px;
}
.editor-toolbar:before {
    margin-bottom: 8px;
}
.editor-toolbar:after {
    margin-top: 8px;
}
.editor-toolbar:hover,
.editor-wrapper input.title:focus,
.editor-wrapper input.title:hover {
    opacity: 0.8;
}
.editor-toolbar.fullscreen {
    width: 100%;
    height: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    background: #fff;
    border: 0;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 9;
}
.editor-toolbar.fullscreen::before {
    width: 20px;
    height: 50px;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(255, 255, 255, 1)),
        color-stop(100%, rgba(255, 255, 255, 0))
    );
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 100%);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}
.editor-toolbar.fullscreen::after {
    width: 20px;
    height: 50px;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
}
.editor-toolbar a {
    display: inline-block;
    text-align: center;
    text-decoration: none !important;
    color: #2c3e50 !important;
    width: 30px;
    height: 30px;
    margin: 0;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;
}
.editor-toolbar a.active,
.editor-toolbar a:hover {
    background: #fff;
    border-color: $secondary-color;
    color: $secondary-color;
}
.editor-toolbar a:before {
    line-height: 30px;
}
.editor-toolbar i.separator {
    display: inline-block;
    width: 0;
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    color: transparent;
    text-indent: -10px;
    margin: 0 6px;
}
.editor-toolbar a.fa-header-x:after {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 65%;
    vertical-align: text-bottom;
    position: relative;
    top: 2px;
}
.editor-toolbar a.fa-header-1:after {
    content: "1";
}
.editor-toolbar a.fa-header-2:after {
    content: "2";
}
.editor-toolbar a.fa-header-3:after {
    content: "3";
}
.editor-toolbar a.fa-header-bigger:after {
    content: "▲";
}
.editor-toolbar a.fa-header-smaller:after {
    content: "▼";
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
    pointer-events: none;
    background: #fff;
    border-color: transparent;
    text-shadow: inherit;
}
@media only screen and (max-width: 700px) {
    .editor-toolbar a.no-mobile {
        display: none;
    }
}
.editor-statusbar {
    padding: 8px 10px;
    font-size: 12px;
    color: #959694;
    text-align: right;
}
.editor-statusbar span {
    display: inline-block;
    min-width: 4em;
    margin-left: 1em;
}
.editor-preview,
.editor-preview-side {
    background: #fafafa;
    overflow: auto;
    display: none;
    box-sizing: border-box;
}
.editor-statusbar .lines:before {
    content: "lines: ";
}
.editor-statusbar .words:before {
    content: "words: ";
}
.editor-statusbar .characters:before {
    content: "characters: ";
}
.editor-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 7;
    padding: $lg-spacing-size;
}
.editor-preview-side {
    position: fixed;
    bottom: 0;
    width: 50%;
    top: 50px;
    right: 0;
    z-index: 9;
    border: 1px solid #ddd;
}
.editor-preview-active,
.editor-preview-active-side {
    display: block;
}
.editor-preview-side > p,
.editor-preview > p {
    margin-top: 0;
}
.editor-preview pre,
.editor-preview-side pre {
    background: #eee;
}
.editor-preview table td,
.editor-preview table th,
.editor-preview-side table td,
.editor-preview-side table th {
    border: 1px solid #ddd;
}
.CodeMirror .CodeMirror-code .cm-tag {
    color: #63a35c;
}
.CodeMirror .CodeMirror-code .cm-attribute {
    color: #795da3;
}
.CodeMirror .CodeMirror-code .cm-string {
    color: #183691;
}
.CodeMirror .CodeMirror-selected {
    background: #d9d9d9;
}
.CodeMirror .CodeMirror-code .cm-header-1 {
    font-size: 200%;
    line-height: 200%;
}
.CodeMirror .CodeMirror-code .cm-header-2 {
    font-size: 160%;
    line-height: 160%;
}
.CodeMirror .CodeMirror-code .cm-header-3 {
    font-size: 125%;
    line-height: 125%;
}
.CodeMirror .CodeMirror-code .cm-header-4 {
    font-size: 110%;
    line-height: 110%;
}
.CodeMirror .CodeMirror-code .cm-comment {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
}
.CodeMirror .CodeMirror-code .cm-link {
    color: #7f8c8d;
}
.CodeMirror .CodeMirror-code .cm-url {
    color: #aab2b3;
}
.CodeMirror .CodeMirror-code .cm-strikethrough {
    text-decoration: line-through;
}
.CodeMirror .CodeMirror-placeholder {
    opacity: 0.5;
}
.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
    background: rgba(255, 0, 0, 0.15);
}

.editor-preview {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    line-height: 1.5;
    color: #24292e;
    line-height: 1.5;
    word-wrap: break-word;
}

.editor-preview .pl-c {
    color: #6a737d;
}

.editor-preview .pl-c1,
.editor-preview .pl-s .pl-v {
    color: #005cc5;
}

.editor-preview .pl-e,
.editor-preview .pl-en {
    color: #6f42c1;
}

.editor-preview .pl-smi,
.editor-preview .pl-s .pl-s1 {
    color: #24292e;
}

.editor-preview .pl-ent {
    color: #22863a;
}

.editor-preview .pl-k {
    color: #d73a49;
}

.editor-preview .pl-s,
.editor-preview .pl-pds,
.editor-preview .pl-s .pl-pse .pl-s1,
.editor-preview .pl-sr,
.editor-preview .pl-sr .pl-cce,
.editor-preview .pl-sr .pl-sre,
.editor-preview .pl-sr .pl-sra {
    color: #032f62;
}

.editor-preview .pl-v,
.editor-preview .pl-smw {
    color: #e36209;
}

.editor-preview .pl-bu {
    color: #b31d28;
}

.editor-preview .pl-ii {
    color: #fafbfc;
    background-color: #b31d28;
}

.editor-preview .pl-c2 {
    color: #fafbfc;
    background-color: #d73a49;
}

.editor-preview .pl-c2::before {
    content: "^M";
}

.editor-preview .pl-sr .pl-cce {
    font-weight: bold;
    color: #22863a;
}

.editor-preview .pl-ml {
    color: #735c0f;
}

.editor-preview .pl-mh,
.editor-preview .pl-mh .pl-en,
.editor-preview .pl-ms {
    font-weight: bold;
    color: #005cc5;
}

.editor-preview .pl-mi {
    font-style: italic;
    color: #24292e;
}

.editor-preview .pl-mb {
    font-weight: bold;
    color: #24292e;
}

.editor-preview .pl-md {
    color: #b31d28;
    background-color: #ffeef0;
}

.editor-preview .pl-mi1 {
    color: #22863a;
    background-color: #f0fff4;
}

.editor-preview .pl-mc {
    color: #e36209;
    background-color: #ffebda;
}

.editor-preview .pl-mi2 {
    color: #f6f8fa;
    background-color: #005cc5;
}

.editor-preview .pl-mdr {
    font-weight: bold;
    color: #6f42c1;
}

.editor-preview .pl-ba {
    color: #586069;
}

.editor-preview .pl-sg {
    color: #959da5;
}

.editor-preview .pl-corl {
    text-decoration: underline;
    color: #032f62;
}

.editor-preview .octicon {
    display: inline-block;
    vertical-align: text-top;
    fill: currentColor;
}

.editor-preview a {
    background-color: transparent;
}

.editor-preview a:active,
.editor-preview a:hover {
    outline-width: 0;
}

.editor-preview strong {
    font-weight: inherit;
}

.editor-preview strong {
    font-weight: bolder;
}

.editor-preview h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.editor-preview img {
    border-style: none;
}

.editor-preview code,
.editor-preview kbd,
.editor-preview pre {
    font-family: monospace, monospace;
    font-size: 1em;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.editor-preview hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

.editor-preview input {
    font: inherit;
    margin: 0;
}

.editor-preview input {
    overflow: visible;
}

.editor-preview [type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

.editor-preview * {
    box-sizing: border-box;
}

.editor-preview input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.editor-preview a {
    color: #0366d6;
    text-decoration: none;
}

.editor-preview a:hover {
    text-decoration: underline;
}

.editor-preview strong {
    font-weight: 600;
}

.editor-preview hr {
    height: 0;
    margin: 15px 0;
    overflow: hidden;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
}

.editor-preview hr::before {
    display: table;
    content: "";
}

.editor-preview hr::after {
    display: table;
    clear: both;
    content: "";
}

.editor-preview table {
    border-spacing: 0;
    border-collapse: collapse;
}

.editor-preview td,
.editor-preview th {
    padding: 0;
}

.editor-preview blockquote {
    margin: 0;
}

.editor-preview ul,
.editor-preview ol {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.editor-preview ol ol,
.editor-preview ul ol {
    list-style-type: lower-roman;
}

.editor-preview ul ul ol,
.editor-preview ul ol ol,
.editor-preview ol ul ol,
.editor-preview ol ol ol {
    list-style-type: lower-alpha;
}

.editor-preview dd {
    margin-left: 0;
}

.editor-preview code {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 12px;
    color: #b71c1c;
}

.editor-preview pre {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 12px;

    code {
        color: inherit;
    }
}

.editor-preview .octicon {
    vertical-align: text-bottom;
}

.editor-preview .pl-0 {
    padding-left: 0 !important;
}

.editor-preview .pl-1 {
    padding-left: 4px !important;
}

.editor-preview .pl-2 {
    padding-left: 8px !important;
}

.editor-preview .pl-3 {
    padding-left: 16px !important;
}

.editor-preview .pl-4 {
    padding-left: 24px !important;
}

.editor-preview .pl-5 {
    padding-left: 32px !important;
}

.editor-preview .pl-6 {
    padding-left: 40px !important;
}

.editor-preview::before {
    display: table;
    content: "";
}

.editor-preview::after {
    display: table;
    clear: both;
    content: "";
}

.editor-preview > *:first-child {
    margin-top: 0 !important;
}

.editor-preview > *:last-child {
    margin-bottom: 0 !important;
}

.editor-preview a:not([href]) {
    color: inherit;
    text-decoration: none;
}

.editor-preview .anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
    line-height: 1;
}

.editor-preview .anchor:focus {
    outline: none;
}

.editor-preview blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
}

.editor-preview blockquote > :first-child {
    margin-top: 0;
}

.editor-preview blockquote > :last-child {
    margin-bottom: 0;
}

.editor-preview kbd {
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fafbfc;
    border: solid 1px #c6cbd1;
    border-bottom-color: #959da5;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #959da5;
}

.editor-preview h1 .octicon-link,
.editor-preview h2 .octicon-link,
.editor-preview h3 .octicon-link,
.editor-preview h4 .octicon-link,
.editor-preview h5 .octicon-link,
.editor-preview h6 .octicon-link {
    color: #1b1f23;
    vertical-align: middle;
    visibility: hidden;
}

.editor-preview h1:hover .anchor,
.editor-preview h2:hover .anchor,
.editor-preview h3:hover .anchor,
.editor-preview h4:hover .anchor,
.editor-preview h5:hover .anchor,
.editor-preview h6:hover .anchor {
    text-decoration: none;
}

.editor-preview h1:hover .anchor .octicon-link,
.editor-preview h2:hover .anchor .octicon-link,
.editor-preview h3:hover .anchor .octicon-link,
.editor-preview h4:hover .anchor .octicon-link,
.editor-preview h5:hover .anchor .octicon-link,
.editor-preview h6:hover .anchor .octicon-link {
    visibility: visible;
}

.editor-preview h1 {
    padding-bottom: 0.3em;
    font-size: 2em;
    border-bottom: 1px solid #eaecef;
}

.editor-preview h2 {
    padding-bottom: 0.3em;
    font-size: 1.5em;
    border-bottom: 1px solid #eaecef;
}

.editor-preview h3 {
    font-size: 1.25em;
}

.editor-preview h4 {
    font-size: 1em;
}

.editor-preview h5 {
    font-size: 0.875em;
}

.editor-preview h6 {
    font-size: 0.85em;
    color: #6a737d;
}

.editor-preview ul,
.editor-preview ol {
    padding-left: 2em;
}

.editor-preview ul ul,
.editor-preview ul ol,
.editor-preview ol ol,
.editor-preview ol ul {
    margin-top: 0;
    margin-bottom: 0;
}

.editor-preview li {
    word-wrap: break-all;
}

.editor-preview li > p {
    margin-top: 16px;
}

.editor-preview li + li {
    margin-top: 0.25em;
}

.editor-preview dl {
    padding: 0;
}

.editor-preview dl dt {
    padding: 0;
    margin-top: 16px;
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
}

.editor-preview table {
    display: block;
    width: 100%;
    overflow: auto;
}

.editor-preview table th {
    font-weight: 600;
}

.editor-preview table th,
.editor-preview table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

.editor-preview table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
}

.editor-preview table tr:nth-child(2n) {
    background-color: #f6f8fa;
}

.editor-preview img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
}

.editor-preview img[align="right"] {
    padding-left: 20px;
}

.editor-preview img[align="left"] {
    padding-right: 20px;
}

.editor-preview code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
}

.editor-preview pre {
    word-wrap: normal;
}

.editor-preview pre > code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
}

.editor-preview .highlight pre {
    margin-bottom: 0;
    word-break: normal;
}

.editor-preview .highlight pre,
.editor-preview pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
}

.editor-preview pre code {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
}

.editor-preview .full-commit .btn-outline:not(:disabled):hover {
    color: #005cc5;
    border-color: #005cc5;
}

.editor-preview kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fafbfc;
    border: solid 1px #d1d5da;
    border-bottom-color: #c6cbd1;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #c6cbd1;
}

.editor-preview :checked + .radio-label {
    position: relative;
    z-index: 1;
    border-color: #0366d6;
}

.editor-preview .task-list-item {
    list-style-type: none;
}

.editor-preview .task-list-item + .task-list-item {
    margin-top: 3px;
}

.editor-preview .task-list-item input {
    margin: 0 0.2em 0.25em -1.6em;
    vertical-align: middle;
}

.editor-preview hr {
    border-bottom-color: #eee;
}

.editor-preview li p {
    margin: 0;
}
