div.tab-section {
    margin-top: 50px;

    h2 {
        margin-top: 0;
        margin-bottom: 24px;
    }

    div.section-info {
        font-size: 12px;
        margin-bottom: 25px;
        padding: 15px;
        border: 1px solid rgba(0, 0, 0, 0.05);
    }
}
