.dashboard-page {
    padding-top: 56px !important;
    .dashboard-page-links {
        & > * {
            margin-right: 4px;
        }
        padding: 16px 0 16px;
    }
    .tags-select {
        .MuiSelect-root {
            max-width: 300px;
        }
    }
    .dashboard-page-content {
        margin-top: 0;
    }
    .story-placeholder {
        padding: $md-spacing-size;
        margin-bottom: $xl-spacing-size;
        & > * {
            margin: $md-spacing-size 0;
        }
    }
}
