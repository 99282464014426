div.simple-header {
    background: #2296f3;
    background: linear-gradient(141deg, #673ab7 0%, #03a9f4 51%, #03a9f4 75%);
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    .simple-header-content {
        max-width: $lg-breakpoint - 1px;
        margin: 0 auto;
        padding: 50px;
        position: relative;
        box-sizing: border-box;
        h1 {
            margin-top: 0;
            color: #fff;
        }
    }
}
