.app-header {
    color: $header-font-color;
    box-shadow: $base-shadow;
    box-sizing: border-box;
    z-index: 10;

    .toolbar-content {
        width: 100%;
        max-width: $lg-breakpoint - 1px;
        position: relative;
        margin: 0 auto;

        padding: $sm-spacing-size 0;
        display: flex;
        align-items: center;

        @media (max-width: 20px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    a.brand-link,
    span.brand-link {
        color: $header-font-color;
        line-height: 36px;
        font-size: 28px;
        position: relative;
        top: 1px;
        text-decoration: none;

        img.app-icon {
            height: 48px;
            vertical-align: middle;
            position: relative;
        }

        span.subtext {
            border-left: 1px solid #ccc;
            padding-left: 16px;
            font-size: 20px;
            position: relative;
            left: -2px;
            top: -1px;
            color: rgba(0, 0, 0, 0.8);
            font-weight: 300;
        }
    }

    .toolbar-spacer {
        width: 80px;
    }
    .toolbar-spacer-sm {
        width: 20px;
    }
    .toolbar-flex-spacer {
        flex: 1;
    }
}
