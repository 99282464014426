.story-gallery {
    margin-top: $xxl-spacing-size !important;

    margin-bottom: $sm-spacing-size !important;
    .image-url {
        display: block;
        padding: $md-spacing-size;
        color: $subdued;
    }
    .attachment-preview-error-msg {
        color: $negative-color;
        padding: $xs-spacing-size $xs-spacing-size 0;
    }
    .attachment-preview {
        position: relative;
        overflow: hidden;
        border-radius: $default-border-radius;
        width: 100%;

        .attachment-preview-img-wrapper {
            position: relative;
            width: 100%;
            height: 120px;

            @media (max-width: $sm-breakpoint - 1px) {
                height: 80px;
            }

            display: block;

            div.attachment-preview-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: top;
            }
            .purged-container {
                background: $disabled-bg-color;
                height: 100%;
                display: flex;
                align-items: center;
                padding: $sm-spacing-size;

                .purged {
                    display: flex;
                    align-items: top;

                    .purged-message {
                        padding-left: $xs-spacing-size;
                        opacity: 0.7;
                        font-size: $xs-font-size;
                        font-style: italic;
                    }
                }
            }
        }
        .attachment-preview-header {
            padding-bottom: 0;
        }
    }
}

.attachment-preview-dialog-toolbar {
    justify-content: center;
    button {
        position: absolute;
        left: $lg-spacing-size;
    }
}
.attachment-preview-dialog-content {
    padding-top: 80px;
    text-align: center;
    img {
        max-width: 100%;
    }
}
