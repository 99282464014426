div.confirmable-action {
    display: inline-block;
}

div.confirmable-action-popover-content {
    padding: 15px;
    div.popover-content-actions {
        margin-top: 15px;
        text-align: right;
    }
}
