@import "storyCards";

.person-list {
    display: flex;
    padding-top: $md-spacing-size;
    .person-list-person {
        text-align: center;
        padding-right: 16px;
        .person-list-person-avatar {
            display: flex;
            justify-content: center;
            .person-list-person-avatar-inactive {
                filter: grayscale(100);
            }
        }
        .person-list-person-name {
            line-height: 16.8px;
            margin-top: $sm-spacing-size;
            font-weight: 300;
            .person-list-person-name-first {
                font-size: $md-font-size;
            }
            .person-list-person-name-last {
                font-size: $xs-font-size;
            }
        }
    }
}
