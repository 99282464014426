.page {
    max-width: $lg-breakpoint - 1px;
    margin: 0 auto;
    padding: $content-spacing-size * 3 $content-spacing-size;

    @media (max-width: 900px) {
        padding: $content-spacing-size * 3 $content-spacing-size / 2;
    }

    position: relative;

    &.no-bg {
        background: none;
    }
    &.no-margin-top {
        padding-top: $headerSize + 15;
    }
    &.no-side-padding {
        padding: $content-spacing-size * 3 0;

        @media (max-width: $lg-breakpoint - 1px) {
            padding: $content-spacing-size * 3 $lg-spacing-size;
        }
    }
}
