.avatar-wrapper {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.avatar-icon-container {
    position: relative;
    .avatar-img {
        position: absolute;
        z-index: 1;
        left: 0;
        height: 48px;
        width: 48px;

        &.sm {
            height: 28px;
            width: 28px;
        }

        &.md {
            height: 36px;
            width: 36px;
        }
    }
}
.avatar-icon-inactive {
    filter: grayscale(100);
}
