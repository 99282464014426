.form {
    margin-top: $md-spacing-size;
    margin-bottom: $xl-spacing-size;

    .form-section {
        position: relative;

        .form-section-paper {
            padding: $lg-spacing-size;
        }

        & > ul {
            padding-top: 0;
        }

        .form-field {
            position: relative;

            .form-control {
                position: relative;

                .type-hint {
                    position: absolute;
                    left: -10px;
                    top: 22px;
                    opacity: 0.5;
                    z-index: 1;

                    &.right {
                        position: relative;
                        display: inline-block;
                        left: auto;
                        top: 2px;
                    }
                }
            }
        }
    }
    .form-actions {
        padding: $lg-spacing-size;
        & > * {
            margin-right: 8px;
        }
    }
}
