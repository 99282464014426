.placeholder-box {
    opacity: 0.8;
    width: 100%;
    height: 15px;
    margin-bottom: 5px;
    display: block;
    margin-bottom: 10px;
    animation: fadeIn 1s infinite alternate;
    text-align: center;

    &.small {
        width: 16px;
        height: 16px;
    }
    &.large {
        width: 32px;
        height: 32px;
        font-size: 24px;
        line-height: 32px;
    }
    &.full {
        width: 100%;
        height: 100%;
    }
    &.extralight {
        background: #fff;
    }
    &.light {
        background: #f0f0f0;
    }
    &.dark {
        background: rgba(33, 149, 243, 0.486);
    }
    span {
        vertical-align: middle;
        position: relative;
        left: 1px;
    }
}
