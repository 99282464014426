.delete-btn {
    color: $negative-color !important;
}
.outcomes-helper-text {
    margin-top: -$sm-spacing-size !important;
}
.working-attachment-preview-list {
    margin-bottom: $sm-spacing-size !important;
    .image-url {
        display: block;
        padding: $md-spacing-size;
        color: $subdued;
    }
    .attachment-preview-error-msg {
        color: $negative-color;
        padding: $xs-spacing-size $xs-spacing-size 0;
    }
    .attachment-preview {
        position: relative;
        overflow: hidden;
        border-radius: $default-border-radius;
        width: 100%;

        &.has-error {
            border: 2px solid $negative-color;
        }

        div.pdf-preview-bg {
            background: #595959;
            width: 100%;
            height: 100%;
            text-align: center;

            div.file-type {
                color: $inverse-text-color;
                text-align: center;
                display: inline-block;
                margin-top: $md-spacing-size;
                font-size: $xl-font-size;
                background: #cc4b4c;
                padding: $xxs-spacing-size $xs-spacing-size;
                border-radius: $small-border-radius;
                margin-bottom: $xs-spacing-size;
            }
            div.file-name {
                color: $inverse-text-color;
                font-size: $sm-font-size;
            }
        }
        .attachment-preview-img-wrapper {
            position: relative;
            width: 100%;
            height: 200px;
            display: block;

            div.attachment-preview-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: top;
            }
            .purged-container {
                background: $disabled-bg-color;
                height: 100%;
                display: flex;
                align-items: center;
                padding: $sm-spacing-size;

                .purged {
                    display: flex;
                    align-items: top;

                    .purged-message {
                        padding-left: $xs-spacing-size;
                        opacity: 0.7;
                        font-size: $xs-font-size;
                        font-style: italic;
                    }
                }
            }
        }
        .attachment-preview-header {
            padding-bottom: 0;
        }
        .attachment-preview-actions {
            display: flex;

            &.no-side-padding {
                padding: $xs-spacing-size 0 $xs-spacing-size 0;
            }
            &.second-row-actions {
                padding-top: 0;
            }

            .delete-attachment-button {
                color: $negative-color;
            }
        }
        .attachment-progress {
            height: 2px;
        }
    }
}
