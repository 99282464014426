.constrained-page-content {
    box-sizing: border-box;
    max-width: $lg-breakpoint;
    margin: 0 auto;

    @media print {
        box-shadow: none;
        padding-bottom: 0;
    }
}
