﻿$base-text-color: #002131;

$dark-base-background-color: #151515;
$dark-base-text-color: #e9e8e9;
$dark-card-background-color: rgba(255, 255, 255, 0.2);
$base-font: "Roboto", sans-serif;
$base-font-size: 14px;
$base-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$item-border: 1px solid #efefef;

$header-background-color: #fff;
$header-font-color: #393939;

$primary-color: #00d6ba;
$secondary-color: #78909c;

$neutral-color: $secondary-color;
$positive-color: #388e3c;
$info-color: #2196f3;
$warning-color: #ef6c00;
$negative-color: #c62828;

$disabled-bg-color: #ddd;
$subdued: #696969;
$default-border-radius: 4px;
$small-border-radius: 2px;
$inverse-text-color: #fff;

$xs-font-size: 10px;
$sm-font-size: 12px;
$md-font-size: 14px;
$lg-font-size: 18px;
$xl-font-size: 20px;
$xxl-font-size: 24px;

$xxs-spacing-size: 1px;
$xs-spacing-size: 2px;
$sm-spacing-size: 4px;
$md-spacing-size: 8px;
$lg-spacing-size: 16px;
$xl-spacing-size: 24px;
$xxl-spacing-size: 40px;
$xxxl-spacing-size: 80px;

$sm-breakpoint: 600px;
$md-breakpoint: 960px;
$lg-breakpoint: 1280px;
$xl-breakpoint: 1920px;

$content-spacing-size: $xxl-spacing-size;

$headerSize: 50px;
