@import "variables";

html,
body {
    @media (prefers-color-scheme: dark) {
        .app-header {
            a.brand-link,
            span.brand-link {
                span.subtext {
                    border-left: 1px solid #777;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
}

@import "AppHeader";
@import "../pages/styles";
@import "../pages/components/misc/styles";
@import "../pages/components/forms/styles";
@import "../pages/components/layout/styles";
@import "../pages/components/items/styles";
@import "../pages/components/people/styles";
@import "../pages/components/stories/styles";
@import "../pages/components/tags/styles";
