.story-linked-item-outcome-sentiment-badge {
    padding: $md-spacing-size;
    background: #e0e0e0;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: $default-border-radius;

    .story-linked-item-outcome-sentiment-badge-inner {
        display: flex;
        align-items: center;
        font-size: $lg-font-size;
        span {
            margin-right: $sm-spacing-size;
        }
    }
    background: #fff;
    &.NEUTRAL {
        color: $neutral-color;
    }
    &.POSITIVE {
        color: $positive-color;
        border-color: $positive-color;
    }
    &.NEGATIVE {
        color: $negative-color;
        border-color: $negative-color;
    }
    &.MIXED {
        color: $warning-color;
        border-color: $warning-color;
    }
}
