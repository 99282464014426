.page-action-bar {
    margin: 25px;

    a {
        text-decoration: none;
    }
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
    @media print {
        display: none;
    }
}
