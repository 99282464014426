.simple-beacon-list-item {
    margin-right: $xl-spacing-size !important;
    .simple-beacon-list-item-inner {
        display: inline-block;
        position: relative;
        border-color: rgba(0, 0, 0, 0.05);
        font-size: $xxl-font-size;

        &.selected {
            opacity: 1;

            &.avoid {
                color: #ef6c00;
            }
        }
        &.considered {
            opacity: 0.85;

            &.avoid {
                border-color: #ef6c00;
            }

            &::after {
                position: absolute;
                top: -15px;
                left: 0;
                font-size: 11px;
                line-height: 11px;
                color: $subdued;
                opacity: 0.5;
            }
        }
        &:hover {
            opacity: 1;
        }
    }
}
